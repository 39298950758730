html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url("./assets/background.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#root {
  width: 100%;
  height: 100%;
}

#main-div {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image {
  outline: 2px solid #fff;
  height: 100px;
  width: 100px;
}

.login-butt {
  width: 120px;
  height: 60px;
  margin: 20px;
}

.logout-butt {
  width: 120px;
  height: 60px;
  position: absolute;
  right: 20px;
  top: 20px;
  
}

.title-image {
  position: absolute;
  width: 500px;
  height: 300px;
  margin-left: 50%;
  margin-top: 2%;
  left: -250px;
}

.howto-list {
  color: white;
}

.main-class {
  margin: 10px;
  width: 150px;
  height: 150px;
}

.main-subclass {
  margin: 10px;
  width: 150px;
  height: 150px;
}

.main-page {
  padding: 50px;
  margin: 0 auto;
  display: grid;
  column-gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.row-main {
  width: 100%;
  letter-spacing: -0.31em;
}

.class-box-main,
.subclass-box-main,
.center-box {
  display: inline-block;
  vertical-align: top;
  border: 3px solid white;
  background-color: rgba(255, 255, 255, .2);

  padding: 5px;
  margin: 0 auto;
  letter-spacing: normal;
  border-radius: 20px;
}

.class-box-main {
  grid-row: 1;
  grid-column: 1;
  width: 400px;
  margin-right: 0px;
}

.class-images {
  width: 340px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.subclass-box-main {
  grid-row: 1;
  grid-column: 3;
  width: 400px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subclass-images {
  width: 340px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.center-box {
  grid-row: 1;
  grid-column: 2;

  margin-top: 150px;
  width: 400px;
  height: 540px;
}

hr.solid {
  border-top: 3px solid #fff;
}

.main-buttons {
  display: grid;
  grid-auto-columns: 1fr;
  row-gap: 15px;


}

.weapons-butt,
.armor-butt {
  display: inline-block;
  vertical-align: top;
  width: 140px;
  height: 54px;

  padding: 5px;
  margin: 0 auto;

}

.weapons-butt {
  grid-row: 1;
  grid-column: 1;
}

.armor-butt {
  grid-row: 1;
  grid-column: 2;
}

.play-butt {
  grid-row: 2;
  grid-column: 1 / span 2;
  justify-self: center;
  align-self: center;
}

#class-text {
  color: black;
  font-size: 52px;
  text-align: center;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: white;
}

#subclass-text {
  color: black;
  font-size: 52px;
  text-align: center;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: white;
}

.column {
  float: left;
  width: 10%;
  padding: 5px;
}


/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}

[type=radio]::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  /* replace with desired color */
  transform: rotate(45deg);
  /* apply rotation */
  margin-right: 8px;
  /* add spacing between checkbox and image */
}

/* HIDE RADIO */
[type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio]+img {
  cursor: pointer;
  outline: 2px solid #fff;
}

/* CHECKED STYLES */
[type=radio]:checked+img {
  outline: 2px solid #f00;
}

/* IMAGE STYLES */
[type=checkbox]+img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=checkbox]:checked+img {
  outline: 2px solid #f00;
}

/* HIDE CHECKBOX */
[type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.title-image-game {
  position: absolute;
  width: 300px;
  margin-left: 50%;
  margin-top: 1%;
  left: -150px;
}

.mega-grid-out {
  padding: 50px;
  margin: auto;
  display: grid;
  position: absolute;
  top: 15%;
  grid-template-rows: repeat(12, 50px);
  grid-template-columns: repeat(36, 50px);
}

.mega-grid {
  padding: 50px;
  margin: auto;
  display: grid;
  position: absolute;
  grid-template-rows: repeat(12, 50px);
  grid-template-columns: repeat(36, 50px);
}

.class {
  width: 200px;
  height: 200px;
  grid-row: 1 / 4;
  grid-column: 29 / 32;
}

.reroll-button {
  width: 200px;
  height: 100px;
  grid-row: 11 / 13;
  grid-column: 31 / 34;
  z-index: 10;
}

.reroll-text-1 {
  color: white;
  font-size: 30px;
}

.reroll-text-2 {
  color: white;
  font-size: 24px;
}

.no-reroll-text {
  color: red;
}

.subclass {
  width: 200px;
  height: 200px;
  grid-row: 1 / 4;
  grid-column: 33 / 36;
}

.super {
  outline: 0px solid #f00;
  width: 300px;
  height: 300px;
}

.super-box {
  width: 300px;
  height: 300px;
  grid-row: 1 / 7;
  grid-column: 1 / 7;
  border: 2px solid white;
  background-color: rgba(255, 255, 255, .2);
}

.reroll-count-box {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, .2);
  grid-row: 6 / 9;
  grid-column: 29 / 34;
  width: 400px;
  height: 200px;
  text-align: center;
  border-radius: 20px;

}

.classabil {
  grid-row: 2 / 4;
  grid-column: 9 / 11;
  z-index: 10;
}

.melee {
  grid-row: 2 / 4;
  grid-column: 12 / 14;
}

.grenade {
  grid-row: 2 / 4;
  grid-column: 15 / 17;
}

.aspect0 {
  grid-row: 2 / 4;
  grid-column: 20 / 22;
}

.aspect1 {
  grid-row: 2 / 4;
  grid-column: 23 / 25;
}

.fragment0 {
  grid-row: 6 / 8;
  grid-column: 9 / 11;
}

.fragment1 {
  grid-row: 6 / 8;
  grid-column: 12 / 14;
}

.fragment2 {
  grid-row: 6 / 8;
  grid-column: 15 / 17;
}

.fragment3 {
  grid-row: 6 / 8;
  grid-column: 18 / 20;
}

.fragment4 {
  grid-row: 6 / 8;
  grid-column: 21 / 23;
}

.weapon {
  grid-row: 10 / 12;
  grid-column: 9 / 11;
}

.armor {
  grid-row: 10 / 12;
  grid-column: 12 / 14;
}

.info-page {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, .2);
  grid-row: 10 / 12;
  grid-column: 1 / 5;
  width: 350px;
  height: 150px;
  text-align: center;
  border-radius: 20px;
}

.info-item-name {
  color: #fff;
}

.home-butt {
  width: 120px;
  height: 60px;
  margin: 20px;
}

.mega-weapons-grid {
  padding: 50px;
  margin: 0 auto;
  display: grid;
  position: absolute;
  top: 15%;
  left: 2.5%;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.buffer-grid{
  padding: 50px;
  margin: auto;
  display: grid;
  margin-top: 0px;
  grid-template-rows: auto auto;
  gap: 20px;
}

.buffer-header{
  color: white;
  font-size: 36px;
  text-align: center;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: black;
}

.weapons-grid {
  margin: auto;
  display: grid;
  margin-top: 0px;
  grid-template-columns: repeat(5, 75px);
  gap: 20px;
}

.exotic-weapons {
  border: 2px solid #fff;
  width: 75px;
  height: 75px;
}

.exotic-armor {
  border: 2px solid #fff;
  width: 75px;
  height: 75px;
}

.exotic-img {
  width: 75px;
  height: 75px;
}

.conf-buttons-grid {
  margin: auto;
  display: grid;
  position: absolute;
  top: 0.5%;
  right: 0.5%;
  gap: 3px;
  grid-template-rows: auto auto auto;
}

.conf-butt {
  width: 225px;
  height: 75px;
  z-index: 10;
}

.login-box{
  display: inline-block;
  vertical-align: top;
  border: 3px solid white;
  background-color: rgba(255, 255, 255, .2);

  padding: 5px;
  margin: 0 auto;
  letter-spacing: normal;
  border-radius: 20px;
  width: 550px;
  height: 630px;
}

.login-grid {
  padding: 50px;
  margin: 0 auto;
  display: grid;
  column-gap: 40px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-top: 4%;
}

.login-label {
  margin-left: 4%;
  color: white;
  font-size: 48px;
  text-align: center;
  -webkit-text-stroke: 1.5px;
  -webkit-text-stroke-color: black;
}

.login-input {
  margin-left: 4%;
  width: 450px;
  height: 40px;

  border: 3px solid white;
  background-color: rgba(38, 38, 38, 0.2);

  font-size: 30px;
  color: white;
}

.login-title {
  position: absolute;
  width: 500px;
  height: 300px;
  margin-left: 50%;
  margin-top: 0%;
  left: -250px;
}

.conf-login {
  display: block;
  width: 220px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15%;

}

.sign-in-info {
  color: white;
  position: absolute;
  top: 15px;
  margin-left: 170px;

}

.auth-errors { 
  color: red;
  text-align: center;
}